import * as React from "react";

function Shaw(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 60.71 22.49" {...props}>
      <path
        d="M45.37 22.33c-.93-.23-1.9-1.1-2.5-2.29l-.5-.95V7.77h4.58v10.45l.96 1.3 1.16-.44V7.77h4.59v9.83l.35.93.35.93 1.23-.24.25-1.4c.13-.78.26-3.36.27-5.73l.02-4.32h4.58l-.02 5.38-.02 5.38-.84 2.4-1 .74-1 .74h-3.7l-2.87-1.9-.62.68c-.98 1.08-3.38 1.6-5.27 1.14zM.1 20.03l.1-2.2 1.86-.22 1.54-1.65L5.49 9.9l1.88-6.07 1.03-1.3a8.39 8.39 0 012.24-1.9L11.85 0h6.53v4.4c0 2.43.08 4.42.17 4.42.09 0 .69-.32 1.32-.7l1.16-.71h2.54l2.36 1.6.25.87c.13.48.26 3.46.28 6.6l.03 5.74h-4.23V11.7l-.55-.56a2.2 2.2 0 00-1.36-.55h-.8l-.59.65-.58.64v10.35H14.5V4.68l-1.41-.37-1.45 1.17-2.03 6.7c-2.18 7.2-2.68 8.24-4.45 9.34l-1.15.71H0zm31.5 1.54a8.37 8.37 0 01-4.06-4.47l-.43-1.3.17-1.52c.1-.83.53-2.25.96-3.15l.8-1.65 2.8-1.85 4.2-.3 3.13 1.2 1.6 2.24.12 5.73.12 5.73h-4.65V20.2l-.02-2.03-2.2 4.06h-.56c-.31-.01-1.2-.3-1.98-.66zm4.76-6.38v-2.81L35.7 12a3.92 3.92 0 00-1.56-.36h-.87l-1.83 2.17v.95c0 .53.25 1.3.55 1.73l.54.78 1.13.33c.63.18 1.5.34 1.93.36l.8.03z"
        fill="#f3f3f3"
      />
    </svg>
  );
}

const MemoShaw = React.memo(Shaw);
export default MemoShaw;
