import React from "react";
import { Box, Stat } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

const Supporters = () => {
  return (
    <>
    <Box
        as="a"
        href="https://www.facebook.com/evandale.tas/"
        target="blank"
        rel="noopener"
        aria-label="No 10 Russell Street Evandale logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./image004.jpg" />
      </Box>
    <Box
        as="a"
        href="https://ironpotbayvineyard.com.au/"
        target="blank"
        rel="noopener"
        aria-label="iron pot bay vineyard logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./ipbv.svg" />
      </Box>
    <Box
        as="a"
        href="https://www.lakeleather.com.au/"
        target="blank"
        rel="noopener"
        aria-label="lake leather logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./lakeleather.svg" />
      </Box>
    <Box
        as="a"
        href="https://www.facebook.com/smallwonderwinesau/"
        target="blank"
        rel="noopener"
        aria-label="Small Wonder vineyard logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./small-wonder.svg" />
      </Box>
    <Box
        as="a"
        href="https://swinginggatewines.com.au/"
        target="blank"
        rel="noopener"
        aria-label="Swinging Gate logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./swinging-gate.png" />
      </Box>
    <Box
        as="a"
        href="https://tamarrivercruises.com.au/"
        target="blank"
        rel="noopener"
        aria-label="Tamar River Cruises logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./tamar-river-cruises-white.svg" />
      </Box>
    <Box
        as="a"
        href="https://winterbrookvineyard.com.au"
        target="blank"
        rel="noopener"
        aria-label="Winter Brook Vineyard logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./winterbrook.svg" />
      </Box>
    <Box
        as="a"
        href="https://www.xldcommodities.com/"
        target="blank"
        rel="noopener"
        aria-label="XLD Commodities logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./xld.svg" />
      </Box>
      <Box
        as="a"
        href="#"
        target="blank"
        rel="noopener"
        aria-label="Westella logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./Westella_ID_with words.jpg" />
      </Box>
          <Box
        as="a"
        href="#"
        target="blank"
        rel="noopener"
        aria-label="Cabbage Tree Hill logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./cabbage tree hill logo.jpg" />
      </Box>
             <Box
        as="a"
        href="#"
        target="blank"
        rel="noopener"
        aria-label="Highwheeler logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./Highwheeler logo.jpg" />
      </Box>
          <Box
        as="a"
        href="https://www.bunnings.com.au/"
        target="blank"
        rel="noopener"
        aria-label="Bunnings logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./Bunnings LOGO.jpg" />
      </Box>
    </>
  );
};

export default Supporters;
