import React from "react";
import { Box, Stat } from "@chakra-ui/react";
import Anteiku from "./Anteiku";
import Autorent from "./Autorent";
import BrianMitchell from "./BrianMitchell";
import ClarendonArms from "./ClarendonArms";
import CoffeeTakeaway from "./CoffeeTakeaway";
import EvandaleVillageStore from "./EvandaleVillageStore";
import HarrisonHumphreys from "./HarrisonHumphreys";
import Hazellbros from "./Hazellbros";
import Ingleside from "./Ingleside";
import LauncestonAirport from "./LauncestonAirport";
import LauncestonDistillery from "./LauncestonDistillery";
import LittleRivers from "./LittleRivers";
import MarleneKlintworth from "./MarleneKlintworth";
import NorthernMidlands from "./NorthernMidlands";
import PrinceOfWales from "./PrinceOfWales";
import Rotary from "./Rotary";
import SausageShop from "./SausageShop";
import SevenNews from "./SevenNews";
import Shaw from "./Shaw";
import SundayMarket from "./SundayMarket";
import TasGov from "./TasGov";
import WaterDynamics from "./WaterDynamics";
import MemoCamperPlus from "./CamperPlus";
import MemoHarrisonAgents from "./HarrisonAgents";
import { StaticImage } from "gatsby-plugin-image";

const SponsorLogos = () => {
  return (
    <>
      <Box
        as="a"
        href="https://www.northernmidlands.tas.gov.au/"
        target="blank"
        rel="noopener"
        aria-label="Northern Midlands Council logo"
        w="100%"
        h="100%"
      >
        <NorthernMidlands />
      </Box>
      <Box
        as="a"
        href="https://hazellbros.com.au/"
        target="blank"
        rel="noopener"
        aria-label="Hazellbros logo"
        w="100%"
        h="100%"
      >
        <Hazellbros />
      </Box>
      <Box
        as="a"
        href="http://www.brianmitchell.com.au/"
        target="blank"
        rel="noopener"
        aria-label="Brian Mitchell MP logo"
        w="100%"
        h="100%"
      >
        <BrianMitchell />
      </Box>
      <Box
        as="a"
        href="https://www.autorent.com.au/"
        target="blank"
        rel="noopener"
        aria-label="Autorent Hertz logo"
        w="100%"
        h="100%"
      >
        <Autorent />
      </Box>
      <Box
        as="a"
        href="https://www.camperplus.com.au/"
        target="blank"
        rel="noopener"
        aria-label="CamperPlus logo"
        w="100%"
        h="100%"
      >
        <MemoCamperPlus />
      </Box>
      <Box
        as="a"
        href="https://launcestondistillery.com.au/"
        target="blank"
        rel="noopener"
        aria-label="Launceston Distillery logo"
        w="100%"
        h="100%"
      >
        <LauncestonDistillery />
      </Box>
      <Box
        as="a"
        href="https://www.shawcontracting.com.au/"
        target="blank"
        rel="noopener"
        aria-label="Shaw Contracting logo"
        w="100%"
        h="100%"
      >
        <Shaw />
      </Box>
      <Box
        as="a"
        href="https://www.facebook.com/EvandaleSundayMarket/"
        target="blank"
        rel="noopener"
        aria-label="Evandale Sunday Market logo"
        w="100%"
        h="100%"
      >
        <SundayMarket />
      </Box>
      <Box
        as="a"
        href="https://www.facebook.com/AnteikuAntiques/"
        target="blank"
        rel="noopener"
        aria-label="Anteiku logo"
        w="100%"
        h="100%"
      >
        <Anteiku />
      </Box>
      <Box
        as="a"
        href="https://waterdynamics.com.au/longford/"
        target="blank"
        rel="noopener"
        aria-label="Water Dynamics logo"
        w="100%"
        h="100%"
      >
        <WaterDynamics />
      </Box>
      <Box aria-label="Marlene Klintworth logo" w="100%" h="100%">
        <MarleneKlintworth />
      </Box>
      <Box
        as="a"
        href="http://www.thesausageshop.com.au/"
        target="blank"
        rel="noopener"
        aria-label="The Sausage Shop logo"
        w="100%"
        h="100%"
      >
        <SausageShop />
      </Box>
      <Box
        as="a"
        href="https://www.tas.gov.au/"
        target="blank"
        rel="noopener"
        aria-label="Tasmanian Government logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./tasgov.jpeg" />
      </Box>
    <Box
        as="a"
        href="https://ducanebrewing.com.au/"
        target="blank"
        rel="noopener"
        aria-label="Du Cane Brewing logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./ducane.png" />
      </Box>
    <Box
        as="a"
        href="https://www.johnglover.com.au/"
        target="blank"
        rel="noopener"
        aria-label="John Glover Prize logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./gloverprize.png" />
      </Box>
    <Box
        as="a"
        href="https://thepumpshedtasmania.com.au/"
        target="blank"
        rel="noopener"
        aria-label="The Pump Shed Tasmania logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./pumpshed.svg" />
      </Box>
      <Box
        as="a"
        href="https://www.facebook.com/profile.php?id=61553627382497"
        target="blank"
        rel="noopener"
        aria-label="Evandale Providore logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./providore.png" />
      </Box>
          <Box
        as="a"
        href="https://www.tasmaniainspires.com.au/"
        target="blank"
        rel="noopener"
        aria-label="Tasmania Inspires logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./inspires.png" />
      </Box>
           <Box
        as="a"
        href="https://launcestonairport.com.au/"
        target="blank"
        rel="noopener"
        aria-label="Launceston Airport logo"
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
      >
        <StaticImage src="./LauncestonAirport_Wordmark_PMS7527 (002) (4) (1).png" />
      </Box>
    </>
  );
};

export default SponsorLogos;
