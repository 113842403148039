import React from "react";
import {
  Box,
  Text,
  HStack,
  Flex,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Drawer,
  IconButton,
  ListIcon,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Container from "./Container";
import Logo from "./SiteLogo";
import { Link } from "gatsby";
import { FiChevronDown, FiChevronUp, FiChevronRight } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";

const Header = () => {
  return (
    <Box as="header" bg="teal.700" py={["1rem", null, "1.25rem"]}>
      <Container size="xl">
        <Flex justify="space-between" align="center">
          <Box
            as={Link}
            to="/"
            aria-label="Evandale Village Fair logo"
            h={["2rem", "2.5rem", "3rem"]}
          >
            <Logo />
          </Box>
          <Box display={["block", null, "none"]}>
            <CurtainMenu />
          </Box>
          <HStack
            color="white"
            spacing="0.75rem"
            display={["none", null, "block"]}
          >
            <AboutMenu />
            <ResultsMenu />
            <Text as={Link} to="/forms/" fontWeight="bold">
              Forms
            </Text>
            <Text as={Link} to="/contact/" fontWeight="bold">
              Contact
            </Text>
          </HStack>
        </Flex>
      </Container>
    </Box>
  );
};

export default Header;

function AboutMenu() {
  return (
    <Menu fontWeight="bold">
      {({ isOpen }) => (
        <>
          <MenuButton fontWeight="bold">
            About{" "}
            <Box
              color="orange.300"
              as={isOpen ? FiChevronUp : FiChevronDown}
              display="inline"
            />
          </MenuButton>
          <MenuList bg="teal.700" border="none">
            <MenuItem
              as={Link}
              to="/history/"
              _hover={{ background: "teal.600" }}
              _focus={{ background: "teal.600" }}
            >
              History
            </MenuItem>
            <MenuItem
              as={Link}
              to="/the-fair/"
              _hover={{ background: "teal.600" }}
              _focus={{ background: "teal.600" }}
            >
              The Fair
            </MenuItem>
            <MenuItem
              as={Link}
              to="/the-races/"
              _hover={{ background: "teal.600" }}
              _focus={{ background: "teal.600" }}
            >
              The Races
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
}

function ResultsMenu() {
  return (
    <Menu fontWeight="bold">
      {({ isOpen }) => (
        <>
          <MenuButton fontWeight="bold">
            Results{" "}
            <Box
              color="orange.300"
              as={isOpen ? FiChevronUp : FiChevronDown}
              display="inline"
            />
          </MenuButton>
          <MenuList bg="teal.700" border="none">
            <MenuItem
              as={Link}
              to="/record-of-race-results/"
              _hover={{ background: "teal.600" }}
              _focus={{ background: "teal.600" }}
            >
              Race Results
            </MenuItem>
            <MenuItem
              as={Link}
              to="/medal-tally/"
              _hover={{ background: "teal.600" }}
              _focus={{ background: "teal.600" }}
            >
              Medal Tally
            </MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
}

function CurtainMenu() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <IconButton
        ref={btnRef}
        aria-label="open navigation menu"
        icon={<BsThreeDotsVertical />}
        colorScheme="teal"
        // bg="orange.400"
        onClick={onOpen}
        fontSize="1.5rem"
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="full"
      >
        <DrawerOverlay />
        <DrawerContent p="2rem" bg="teal.800" color="white">
          <DrawerCloseButton right="5vw" top="0.75rem" />
          <Box as="nav" display="flex" alignContent="center" h="100%">
            <List
              textAlign="left"
              spacing="1rem"
              h="max-content"
              alignSelf="center"
            >
              <ListItem display="flex" fontSize="1.25rem">
                <ListIcon
                  as={FiChevronRight}
                  color="orange.300"
                  alignSelf="center"
                />
                <Link to="/">Home</Link>
              </ListItem>
              <Box>
                <Text fontWeight="bold">About</Text>
                <List pl="0.75rem" spacing="0.5rem">
                  <ListItem display="flex" fontSize="1.25rem">
                    <ListIcon
                      as={FiChevronRight}
                      color="orange.300"
                      alignSelf="center"
                    />
                    <Link to="/history/">The History</Link>
                  </ListItem>
                  <ListItem display="flex" fontSize="1.25rem">
                    <ListIcon
                      as={FiChevronRight}
                      color="orange.300"
                      alignSelf="center"
                    />
                    <Link to="/the-fair/">The Fair</Link>
                  </ListItem>
                  <ListItem display="flex" fontSize="1.25rem">
                    <ListIcon
                      as={FiChevronRight}
                      color="orange.300"
                      alignSelf="center"
                    />
                    <Link to="/the-races/">The Races</Link>
                  </ListItem>
                </List>
              </Box>
              <Box>
                <Text fontWeight="bold">Results</Text>
                <List pl="0.75rem" spacing="0.5rem">
                  <ListItem display="flex" fontSize="1.25rem">
                    <ListIcon
                      as={FiChevronRight}
                      color="orange.300"
                      alignSelf="center"
                    />
                    <Link to="/record-of-race-results/">Race Results</Link>
                  </ListItem>
                  <ListItem display="flex" fontSize="1.25rem">
                    <ListIcon
                      as={FiChevronRight}
                      color="orange.300"
                      alignSelf="center"
                    />
                    <Link to="/medal-tally/">Medal Tally</Link>
                  </ListItem>
                </List>
              </Box>
              <ListItem display="flex" fontSize="1.25rem">
                <ListIcon
                  as={FiChevronRight}
                  color="orange.300"
                  alignSelf="center"
                />
                <Link to="/forms/">Forms</Link>
              </ListItem>
              <ListItem display="flex" fontSize="1.25rem">
                <ListIcon
                  as={FiChevronRight}
                  color="orange.300"
                  alignSelf="center"
                />
                <Link to="/contact/">Contact</Link>
              </ListItem>
            </List>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
}
