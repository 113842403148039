import React from "react";
import { Box, Heading, Text, VStack, Grid } from "@chakra-ui/react";
import Container from "./Container";
import { FaFacebook } from "react-icons/fa";
import { Link } from "gatsby";
import SponsorLogos from "src/components/sponsors/SponsorLogos";
import Supporters from "src/components/supporters/Supporters";

const Footer = () => {
  return (
    <Box as="footer" bg="teal.700" color="white" py="3rem">
      <Container size="md">
    <Text mb="3rem">We acknowledge the Palawa people as the traditional and ongoing owners  and custodians of Lutruwita/Tasmania. We pay our respects to elders past and present.</Text>
        <Grid gridTemplateColumns={[null, null, "16rem 1fr"]} gap="3rem">
          <VStack align="flex-start" spacing="1rem">
            <Heading as="span" display="block" size="lg">
              Get in Touch
            </Heading>
            <a
              href="https://www.facebook.com/EvandaleVillageFair"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="View Facebook page"
            >
              <FaFacebook size={24} />
            </a>
            <Text>
              Visit the <Link to="/contact/">contact page</Link> if you have any
              questions.
            </Text>
          </VStack>
          <Box>
            <Heading as="span" display="block" size="lg">
              Proudly Sponsored By
            </Heading>
            <Grid
              gridTemplateColumns="repeat(auto-fill, minmax(3.5rem, 1fr))"
              gap="1rem"
              mt="2rem"
            >
              <SponsorLogos />
            </Grid>
            <Heading as="span" display="block" mt="4rem" size="lg">
              Supported By
            </Heading>
            <Grid
              gridTemplateColumns="repeat(auto-fill, minmax(3.5rem, 1fr))"
              gap="1rem"
              mt="2rem"
            >
                <Supporters />
           </Grid>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
