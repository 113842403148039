import React from "react";
import { Box } from "@chakra-ui/react";

const Container = ({ children, size, ...props }) => {
  return (
    <Box w="90vw" maxW={`container.${size}`} mx="auto" {...props}>
      {children}
    </Box>
  );
};

export default Container;
